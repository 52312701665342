import React, { useState } from "react"
import * as Yup from "yup"
import RcGenericTitle from "../../page-components/content/rcGenericTitle"
import { Formik, Field } from "formik"
import axios from "axios"
import { getStrapiUrl } from "../../helper/uri"
import { toast } from 'react-toastify';
import * as styles from "./betriebsmarkt-contact.module.scss"
import RcFormErrors from "../../page-components/helper/rcFormErrors"
import {
  BZH_BRANCHEN_SELECT,
  BZH_COUNTRIES, BZH_LEGAL_FORMS,
  BZH_REGIONS_AT,
  BZH_REGIONS_DE,
  BZH_TRANSACTION_REASONS_SELECT
} from "../../helper/bzh-helper"
import MultiSelect from "../../components/multi-select/multi-select"

const BetriebsmarktContactSuche = ( ) => {

  const [ sent, setSent ] = useState(false);

  const formSchema = Yup.object().shape(
    {
      mail: Yup.string().email("Ungültige Mail-Adresse").required("E-Mail Adresse notwendig"),
      name: Yup.string().required("Ihr vollständiger Name ist notwendig"),
      telefon: Yup.string().required("Ihre Telefonnummer ist notwendig")
    }
  )

  return <>

    <div className={"col-12"}>

      <RcGenericTitle>
        <h3>Nachfolger Suche</h3>
        <p>
          Wenn Sie in unserer Datenbank nicht das richtige gefunden haben, können Sie uns gerne eine Anfrage senden und wir suchen nach einem passenden Nachfolger für Sie.
        </p>
      </RcGenericTitle>

    </div>

    {
      sent &&
      <div className={"col-12"}>
        <p className={"my-4"}>Vielen Dank für Ihre Anfrage. Wir melden uns bald bei Ihnen.</p>
      </div>
    }

    {!sent &&

      <Formik
        initialValues={
          {
            mail: '',
            name: '',
            telefon: '',
            strasse: '',
            nachricht: '',

            descriptionShort: '',
            transactionReasonIds: [],
            branchenIds: [],
            legalforms: '',
            employeeCount: '',
            country: '',
            region: '',
            turnover: '',

            plz: '',
            ort: '',

            firma: ''
          }
        }
        validationSchema={formSchema}
        onSubmit={async (values, { setSubmitting }) => {

          try {
            await axios.post(getStrapiUrl() + "/submit-contact/betriebsmarkt-offer", { ...values });
            setSubmitting(false);
            setSent(true);
          } catch (err) {
            toast("Es ist ein unbekannter Fehler passiert.");
          }

        }}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
          <form className={"rc-form row mb-5 px-4"} onSubmit={handleSubmit}>


            <div className={"col-12 col-lg-6"}>

              <h5>Persönliche Daten</h5>
              <p className={styles.info}>Um Sie später kontaktieren zu können, benötigen wir von Ihnen die Grunddaten</p>

              <div className="rc-form-control">
                <label htmlFor="firma">Firma*</label>

                <input type={"text"} name={"firma"} id={"firma"}
                       onChange={handleChange}
                       onBlur={handleBlur}/>
              </div>
              <div className="rc-form-control">
                <label>Name*</label>

                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />

              </div>
              <div className="rc-form-control">
                <label htmlFor="mail">E-Mail*</label>

                <input
                  type="email"
                  id={"mail"}
                  name="mail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mail}
                />

                {errors.email && touched.email && errors.email}
              </div>
              <div className="rc-form-control">
                <label>Telefon*</label>

                <input
                  type="phone"
                  name="telefon"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.telefon}
                />

              </div>
            </div>

            <div className={"col-12 col-lg-6"}>

              <h5>Firmenkennzahlen</h5>
              <p className={styles.info}>Bittte geben Sie einige Informationen zu Ihrem Unternehmen Bzw. der Übergabeart bekannt</p>



              <div className="rc-form-control">
                <label>Kurzbeschreibung Ihres Unternehmens</label>

                <textarea name={"descriptionShort"}
                          rows={4}
                          onChange={handleChange}
                          onBlur={handleBlur} />

              </div>



              <div className="rc-form-control">
                <label>Übergabe-/Verkaufsart</label>

                <Field options={BZH_TRANSACTION_REASONS_SELECT}
                       component={MultiSelect}
                       name={"transactionReasonIds"}
                       isMulti={true} />

              </div>

              <div className="rc-form-control">
                <label>Branche(n)*</label>

                <Field options={BZH_BRANCHEN_SELECT}
                       component={MultiSelect}
                       name={"branchenIds"}
                       isMulti={true} />

              </div>


              <div className="rc-form-control">
                <label>Rechtsform</label>

                <select name={"legalforms"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.legalforms}>
                  <option value={""}>bitte wählen Sie aus</option>

                  {BZH_LEGAL_FORMS.map(
                    l => <option value={l.id}>{l.title}</option>
                  )}
                </select>
              </div>

              <div className="rc-form-control">

                <label>Mitarbeiter</label>

                <input type="number" name="employeeCount"
                       onChange={handleChange} onBlur={handleBlur}
                       value={values.employeeCount} />

              </div>

              <div className="rc-form-control">
                <label>Umsatz</label>

                <input type="number" name={"turnover"}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.turnover} />

              </div>


              <div className="rc-form-control">
                <label>Land</label>

                <select name={"country"}
                        value={values.country}
                        onChange={handleChange}
                        onBlur={handleBlur}>

                  <option value={""}>bitte Auswahl treffen</option>
                  { BZH_COUNTRIES.map(b => <option value={b.value}>{b.label}</option> )}

                </select>
              </div>

              <div className={"rc-form-control " + ((values.country === "AT" || values.country === "DE") ? " d-block " : " display-none ") } >
                <label>Region</label>

                <select name={"region"}
                        value={values.region}
                        onChange={handleChange}
                        onBlur={handleBlur}>

                  <option value={""}>bitte Auswahl treffen</option>

                  { values.country === "AT" && BZH_REGIONS_AT.map(b => <option>{b}</option> ) }
                  { values.country === "DE" && BZH_REGIONS_DE.map(b => <option>{b}</option> ) }

                </select>

              </div>

            </div>


            <div className={"col-12 col-lg-6"}>
              <h5>Persönliche Adresse</h5>

              <p className={styles.info}>Aufgrund von Compliance Regeln brauchen wir Ihre vollständige Anschrift.</p>


              <div className="rc-form-control">
                <label>Straße*</label>

                <input
                  type="text"
                  id="strasse"
                  name="strasse"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.strasse}
                />

              </div>
              <div className="rc-form-control">
                <label htmlFor="plz">Postleitzahl*</label>

                <input id="plz"
                       name="plz"
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.plz}/>
              </div>
              <div className="rc-form-control">
                <label htmlFor="ort">Ort*</label>

                <input id="ort" name="ort"
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.ort}/>
              </div>


              <div className={styles.appendix + " mt-5"}>
                *) Sämtliche uns übermittelten Informationen und Daten werden diskret und anonym behandelt. Sie werden
                auch nicht weitergegeben oder längerfristig gespeichert.
              </div>

            </div>


            <div className={"col-12 col-lg-6"}>

              <h5>Nachricht</h5>

              <p className={styles.info}>Hinterlassen Sie uns gerne eine kurze Nachricht mit ergänzenden
                Informationen.</p>

              <div className="rc-form-control">
                <label>Nachricht*</label>

                <textarea name="nachricht" id="nachricht" rows="10"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nachricht}/>
              </div>

              <RcFormErrors touched={touched} errors={errors} className={"mb-4"} />

              <button type="submit" disabled={isSubmitting}>Absenden</button>
            </div>
          </form>
        )}


      </Formik>
    }

  </>;

};

export default BetriebsmarktContactSuche;
