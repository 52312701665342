import React from 'react';
import Layout from "../page-components/layout"
import BetriebsmarktContactOffer from "../pages-parts/betriebsmarkt/betriebsmarkt-contact-offer"

const BetriebsmarktContactOfferPage = () => {

  return <Layout>
    <div className="container bg-white h-100">
      <div className="row">
          <BetriebsmarktContactOffer />
      </div>
    </div>
  </Layout>

};

export default BetriebsmarktContactOfferPage;
